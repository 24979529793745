<template>
  <div class="user-view container">
    <tab-component :tabs="tabs">
      <template>
        <h1>{{ newUser ? "Registrér ny bruger" : "Redigér bruger" }}</h1>
      </template>
    </tab-component>

    <section>
      <section-header title="Stamdata">
        <template>
          <b-button type="is-light"
                    v-if="!newUser"
                    @click="deleteUser"
                    :disabled="isCurrentUser"
                    expanded>
            Slet bruger
          </b-button>
          <b-button type="is-primary"
                    @click="submit"
                    :loading="loading"
                    expanded>
            {{ newUser ? "Opret bruger" : "Gem ændringer" }}
          </b-button>
        </template>
      </section-header>

      <div class="container">
        <div class="columns">
          <div class="column">
            <b-field label="Name" class="column" :message="validationMessage('name')" :type="isValid('name') ? '' : 'is-danger'">
              <b-input v-model="formAppUser.name"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="E-mail" class="column" :message="validationMessage('email')" :type="isValid('email') ? '' : 'is-danger'">
              <b-input v-model="formAppUser.email" type="email" :disabled="!newUser"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Role" class="column" :message="validationMessage('role')" :type="isValid('role') ? '' : 'is-danger'">
              <b-select v-model="formAppUser.role" placeholder="Select a role" required expanded>
                <option v-for="(role, index) in roles" :key="index" :value="role">
                  {{ role }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Er Aktiv" class="column is-3">
              <div class="fake-control">
                <b-switch v-model="formAppUser.isActive" type="is-primary" size="is-medium">{{ formAppUser.isActive ? 'Brugeren er aktiv' : 'Brugeren er inaktiv' }}</b-switch>
              </div>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-3" v-if="newUser">
            <b-field label="Password" class="column" :message="validationMessage('password')" :type="isValid('password') ? '' : 'is-danger'">
              <b-input v-model="formAppUser.password" type="password"></b-input>
            </b-field>
          </div>
        </div>
      </div>
    </section>
    <dialog-modal :title="deleteModalTitle"
                  :is-open="deleting !== undefined"
                  @closed="deleting = undefined;">
      <h1>
        Bekræft venligst at du ønsker at slette brugeren.
      </h1>
      <template #additionalControls="{controls}">
        <button class="button is-primary"
                @click="deleteUserConfirmed(controls)">
          Slet brugeren
        </button>
      </template>
    </dialog-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ApiState from '../enums/APISTATE';

export default {
  name: 'user-view',
  props: {
    userId: {
      type: String,
      default: null
    }
  },
  data: () => ({
    formAppUser: {},
    roles: ['User', 'Admin'],
    deleting: undefined,
  }),
  async mounted() {
    await this.loadAppUser(this.userId);
    this.formAppUser = {...this.appUser};

    this.assignCriteria([
      this.criteriaRequired('name', () => this.formAppUser.name),
      this.criteriaRequired('email', () => this.formAppUser.email),
      this.criteriaRequired('role', () => this.formAppUser.role),
      this.criteriaRequired('password', () => this.formAppUser.password),
      this.criteriaEmail('email', () => this.formAppUser.email),
    ]);
  },
  computed: {
    ...mapGetters('appUsers', ['appUserApiState', 'appUser']),
    ...mapGetters(['currentUser']),
    ...mapGetters('validationStore', ['isValid', 'validationMessage', 'criteriaValue', 'criteriaRequired', 'criteriaEmail']),
    deleteModalTitle() {
      if (this.deleting) {
        return this.deleting.name;
      }
      return '';
    },
    isCurrentUser() {
      return this.userId === this.currentUser.id;
    },
    loading() {
      return this.appUserApiState !== ApiState.LOADED;
    },
    newUser() {
      return Object.prototype.hasOwnProperty.call(this.formAppUser, 'id') !== true;
    },
    tabs() {
      if (this.newUser) {
        return [{ title:'Stamdata', to: '/users/new' }];
      }
      return [
        { title: 'Stamdata', to: `/users/${this.userId}/edit`, disabled: false },
        { title: 'Password', to: `/users/${this.userId}/password`, disabled: this.isCurrentUser}
      ];
    }
  },
  methods: {
    ...mapActions('appUsers', ['loadAppUser', 'updateAppUser', 'registerAppUser', 'deleteAppUser']),
    ...mapActions('validationStore', ['assignCriteria', 'validate']),
    goBack() {
      this.$router.push({name: 'Users'});
    },
    async submit() {
      try {
        if (this.validate()) {
          if (this.newUser) {
            await this.registerAppUser(this.formAppUser);
          }
          else {
            await this.updateAppUser(this.formAppUser);
          }
          if (this.appUserApiState !== ApiState.ERROR) {
            this.goBack();
          }
          else {
            // show error?
          }
        }
      }
      catch (error) {
        this.$buefy.toast.open({
          message: 'Fejl ved opret af bruger.',
          type: 'is-danger'
        });
      }
    },
    deleteUser() {
      this.deleting = {...this.appUser};
    },
    async deleteUserConfirmed(controls) {
      await this.deleteAppUser(this.deleting.id);
      controls.close();
      this.goBack();
    }
  }
  
}
</script>

<style lang="scss" scoped>
.user-view {
  .fake-control {
    min-height: 40px;
    padding: 3px;
  }
}
</style>